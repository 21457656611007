.App {
  padding: 0;
  width: 100%;
  background-color: #E2DFD2;
}

/* .Content {
  width: 100vw;
  margin: 0 auto;
  padding: 0px;
  border: 2px solid black;
} */
.Header {
  background: conic-gradient(#000 90deg,#f6bc29 0 180deg,#000 0 270deg, #f6bc29 0);
  background-size: 50px 50px;
  height: 75px; /* Set the height of your header */
  width: 100%;
  position: sticky;
  top: 0;
} 

.introduction {
  background-color: #f2f2f2;
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.driver_info_stack {
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-card {
  display: flex;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
}

.card-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-content h3 {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
}

.card-content p {
  margin: 0;
  font-size: 1rem;
}

.card-content button {
  width: 150px;
}

.scroller {
  max-width: 500px; /* Set the max width */
  margin: 0 auto;   /* Center the carousel horizontally */
}

.scroller img {
  max-height: 300px; /* Set the max height */
  width: 100%;       /* Make the image responsive */
  object-fit: cover;  /* Ensure image maintains aspect ratio */
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto; /* Side images are fixed, center grows */
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.side-image {
  width: 500px; /* Fixed width for side images */
  height: auto;
  /* border: 2px solid black; */
}

.center-text {
  text-align: center;
  padding: 0 20px;
}





/* .container {
  max-width: 800px;
  margin: 0 auto;
} */

